import { LockOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Button, Form, Input, message } from "antd";
import config from "app/envConfig";
import { resetStateAction } from "common/redux/resetStateAction";
import { useChangePasswordMutation } from "features/auth/authAPI";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const StyledForm = styled(Form)`
  width: 300px;
  margin-top: 12px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 72px);
`;

function ChangePassword() {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const { logout } = useAuth0();

  const onFinish = async (values) => {
    const result = await changePassword(values);
    if ("data" in result) {
      dispatch(resetStateAction());
      message.success("Successfully changed password!", 2);

      if (config.useAuth0) {
        logout({
          logoutParams: {
            returnTo: `${window.location.origin}/login`,
          },
        });
      }
    }
    if ("error" in result) {
      setErrorMessage("Failed to change password");
    }
  };

  return (
    <StyledContainer>
      <h3>Change Password</h3>
      <StyledForm
        autoComplete="off"
        onFinish={onFinish}
        className="change-password-form"
        initialValues={{
          old: "",
          new: "",
          confirmNew: "",
        }}
      >
        <Form.Item
          name="oldPassword"
          hasFeedback
          rules={[{ required: true, message: "Please enter old password" }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            autoComplete="new-password"
            placeholder="Enter current password"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="newPassword"
          rules={[
            {
              required: true,
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
              message:
                "Password must be at least 8 characters with at least 1 upper case, 1 lower case, 1 number, and 1 special character",
            },
            { required: true, message: "Please enter new password" },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder="Enter new password"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="confirmPassword"
          rules={[
            { required: true, message: "Confirm new password" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords don't match"));
              },
            }),
          ]}
          dependencies={["newPassword"]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder="Confirm new password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: "100%", height: "50px" }}
            loading={isLoading}
          >
            Change Password
          </Button>
        </Form.Item>
        <Form.Item>{errorMessage ? <Alert message={errorMessage} type="error" /> : null}</Form.Item>
      </StyledForm>
    </StyledContainer>
  );
}

export default ChangePassword;
