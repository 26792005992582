import AktosLogo from "assets/aktos-logo-1004x923.png";
import ACSLogo from "assets/acs-logo-160x36.png";
import CSLLogo from "assets/csl-logo-160x100.png";
import LiveOakLogo from "assets/liveoak-logo-143x120.png";
import NCSPlusLogo from "assets/ncsplus-newlogo-160x60.png";
import SolverityLogo from "assets/solverity-logo-160x65.png";
import TSCLogo from "assets/tsc-logo-200x99-transparent.png";

export const US_STATES = [
  { name: "Alabama", abbreviation: "AL" },
  { name: "Alaska", abbreviation: "AK" },
  { name: "Arizona", abbreviation: "AZ" },
  { name: "Arkansas", abbreviation: "AR" },
  { name: "California", abbreviation: "CA" },
  { name: "Colorado", abbreviation: "CO" },
  { name: "Connecticut", abbreviation: "CT" },
  { name: "Delaware", abbreviation: "DE" },
  { name: "Florida", abbreviation: "FL" },
  { name: "Georgia", abbreviation: "GA" },
  { name: "Hawaii", abbreviation: "HI" },
  { name: "Idaho", abbreviation: "ID" },
  { name: "Illinois", abbreviation: "IL" },
  { name: "Indiana", abbreviation: "IN" },
  { name: "Iowa", abbreviation: "IA" },
  { name: "Kansas", abbreviation: "KS" },
  { name: "Kentucky", abbreviation: "KY" },
  { name: "Louisiana", abbreviation: "LA" },
  { name: "Maine", abbreviation: "ME" },
  { name: "Maryland", abbreviation: "MD" },
  { name: "Massachusetts", abbreviation: "MA" },
  { name: "Michigan", abbreviation: "MI" },
  { name: "Minnesota", abbreviation: "MN" },
  { name: "Mississippi", abbreviation: "MS" },
  { name: "Missouri", abbreviation: "MO" },
  { name: "Montana", abbreviation: "MT" },
  { name: "Nebraska", abbreviation: "NE" },
  { name: "Nevada", abbreviation: "NV" },
  { name: "New Hampshire", abbreviation: "NH" },
  { name: "New Jersey", abbreviation: "NJ" },
  { name: "New Mexico", abbreviation: "NM" },
  { name: "New York", abbreviation: "NY" },
  { name: "North Carolina", abbreviation: "NC" },
  { name: "North Dakota", abbreviation: "ND" },
  { name: "Ohio", abbreviation: "OH" },
  { name: "Oklahoma", abbreviation: "OK" },
  { name: "Oregon", abbreviation: "OR" },
  { name: "Pennsylvania", abbreviation: "PA" },
  { name: "Rhode Island", abbreviation: "RI" },
  { name: "South Carolina", abbreviation: "SC" },
  { name: "South Dakota", abbreviation: "SD" },
  { name: "Tennessee", abbreviation: "TN" },
  { name: "Texas", abbreviation: "TX" },
  { name: "Utah", abbreviation: "UT" },
  { name: "Vermont", abbreviation: "VT" },
  { name: "Virginia", abbreviation: "VA" },
  { name: "Washington", abbreviation: "WA" },
  { name: "West Virginia", abbreviation: "WV" },
  { name: "Wisconsin", abbreviation: "WI" },
  { name: "Wyoming", abbreviation: "WY" },
];

export const DATE_FORMAT = "MM/DD/YYYY";
export const TIME_FORMAT = "hh:mm A z";
export const DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm A z";

export const PROCESSED_PAYMENT_METHODS = ["process_card", "process_ach"];

// All payments made toe the Creditor are explicitly logged as a 'Paid to Creditor'
// payment method.
export const PAID_TO_CREDITOR_PAYMENT_METHOD_OPTIONS = [
  { value: "paid_to_creditor", label: "Paid to Creditor" },
  { value: "paid_to_creditor_legal", label: "Paid to Creditor (Legal Payment)" },
];

export const CREDITOR_PORTAL_PAYMENT_METHOD_OPTIONS = [
  { value: "paid_to_creditor", label: "Paid to Creditor" },
];

// All payments made to a Forwarding Entity are explicitly logged as a 'Forwarding Entity'
// payment method.
export const PAID_TO_FORWARDING_ENTITY_PAYMENT_METHOD_OPTIONS = [
  { value: "forwarding_entity", label: "Forwarding Entity" },
  { value: "forwarding_entity_legal", label: "Forwarding Entity (Legal Payment)" },
];

// Payment Plans can be set up for payments to be processed in the future. Doesn't make sense
// to have payment methods that are only for logging (cash, check, online portal).
export const PROCESS_PAYMENT_METHOD_OPTIONS = [
  { value: "process_card", label: "Card" },
  { value: "process_ach", label: "ACH" },
];

export const PAID_TO_AGENCY_LOGGED_PAYMENT_METHOD_OPTIONS = [
  { value: "ach", label: "ACH" },
  { value: "card", label: "Card" },
  { value: "cash", label: "Cash" },
  { value: "check", label: "Check" },
  { value: "online_portal", label: "Online Portal" },
  { value: "bankruptcy", label: "Bankruptcy Payment" },
  { value: "legal", label: "Legal" },
  { value: "promise_to_pay", label: "Promise to Pay" },
  { value: "other", label: "Other" },
];

export const LEGACY_PAYMENT_METHOD_OPTIONS = [{ value: "legacy", label: "Legacy" }];

export const PAYMENT_METHOD_OPTIONS = [
  {
    label: "Process options",
    options: PROCESS_PAYMENT_METHOD_OPTIONS,
  },
  {
    label: "Logging options",
    options: PAID_TO_AGENCY_LOGGED_PAYMENT_METHOD_OPTIONS,
  },
];

export const ALL_PAYMENT_METHOD_VALUES = PAID_TO_AGENCY_LOGGED_PAYMENT_METHOD_OPTIONS.concat(
  PAID_TO_CREDITOR_PAYMENT_METHOD_OPTIONS,
)
  .concat(PAID_TO_FORWARDING_ENTITY_PAYMENT_METHOD_OPTIONS)
  .concat(PROCESS_PAYMENT_METHOD_OPTIONS)
  .concat(LEGACY_PAYMENT_METHOD_OPTIONS)
  .map((option) => option.value);

export const PAYMENT_INTENT_STATUS = {
  NEW: "new",
  EXECUTED: "executed",
  REFUNDED: "refunded",
  VOIDED: "voided",
  EXECUTE_ERROR: "execute_error",
  REFUND_ERROR: "refund_error",
  VOID_ERROR: "void_error",
};

export const PAYMENT_TRANSACTION_STATUS = {
  SUCCESS: "success",
  DECLINED: "declined",
  ERROR: "error",
};

export const CREDITOR_DOCUMENT_TYPE_OPTIONS = [
  { value: "credit_report", label: "Credit Report" },
  { value: "creditor_communication", label: "Creditor Communication" },
  { value: "invoice", label: "Invoice" },
  { value: "invoice_check", label: "Invoice Check" },
];

export const ORGANIZATION_STATUS = {
  active: "active",
  inactive: "inactive",
  prospect: "prospect",
};

export const COMMUNICATION_TASK_STATUS = {
  SCHEDULED: "scheduled",
  SUCCESS: "success",
  FAILED: "failed",
};

export const DEFAULT_LOGIN_REDIRECT_SCREEN_OPTIONS = [
  { value: "", label: "None" },
  { value: "accounts", label: "Accounts" },
  { value: "creditors", label: "Client Worklist" },
];

// This is the default route if a user does not have a defaultLoginRedirectScreen
export const DEFAULT_LOGIN_REDIRECT_SCREEN = "accounts";

// This is settings for whitelabeling solution
// any new subdomains custom settings for an agency will require a new object here to configure
// https://picresize.com/ to resize logos
export const WHITELABEL_SETTINGS = [
  {
    logo: AktosLogo,
    slug: "test",
    website: "https://www.google.com",
    displayName: "test",
    address: "123 Main St Anytown, USA 12345",
    phone: "(123)-456-7890",
    email: "testagency@gmail.com",
  },
  {
    logo: ACSLogo,
    slug: "acs",
    website: "https://www.acscollections.com/",
    displayName: "American Collection Systems",
    address: "202 W Johnstown Rd., Gahanna, OH 43230",
    phone: "(800)-561-9118",
    email: "customerservice@acscollections.com",
  },
  {
    logo: CSLLogo,
    slug: "csl",
    website: "https://www.csl.com",
    displayName: "CSL",
    address: "123 Main St Anytown, USA 12345",
    phone: "(123)-456-7890",
    email: "csl@gmail.com",
  },
  {
    logo: NCSPlusLogo,
    slug: "ncs",
    website: "https://www.ncs.com",
    displayName: "NCSPlus",
    address: "123 Main St Anytown, USA 12345",
    phone: "(123)-456-7890",
    email: "ncs@gmail.com",
    signupProps: {
      preTransform: (values) => {
        return {
          ...values,
          // creditorCode could be AAAAAA, AAAAAA01, AAAAAA02, etc. We only want the first 6 characters.
          creditorCode: values.creditorCode.substring(0, 6),
        };
      },
    },
  },
  {
    logo: LiveOakLogo,
    slug: "liveoak",
    website: "https://www.liveoak.com",
    displayName: "Live Oak",
    address: "123 Main St Anytown, USA 12345",
    phone: "(123)-456-7890",
    email: "liveoak@gmail.com",
  },
  {
    logo: SolverityLogo,
    slug: "solv",
    website: "https://www.solveritynw.com/",
    displayName: "Solverity",
    address: "9707 NE 54th Street, Suite A, Vancouver, WA 98662",
    phone: "(360)-604-8514",
    email: "help@solveritynw.com",
  },
  {
    logo: TSCLogo,
    slug: "tsc",
    website: "https://www.tsc.com",
    displayName: "TSC",
    address: "123 Main St Anytown, USA 12345",
    phone: "(123)-456-7890",
    email: "tsc@gmail.com",
  },
];
