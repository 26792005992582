import { Button, Flex, Result } from "antd";
import {
  PAYMENT_VIEW_SCREEN_ENUMS,
  setCurrentView,
} from "features/consumerPortalPaymentPage/paymentsSlice";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const StyledFlex = styled(Flex)`
  height: 100%;
`;

function PaymentConfirmation() {
  const dispatch = useDispatch();

  return (
    <StyledFlex align="center" justify="center">
      <Result
        status="success"
        subTitle="Your payment has been successfully processed."
        title="Thank you for your payment!"
        extra={
          <Button
            type="link"
            onClick={() =>
              dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.SelectAccountsForPayment))
            }
          >
            Go back to your accounts
          </Button>
        }
      />
    </StyledFlex>
  );
}

export default PaymentConfirmation;
