import moment from "moment-timezone";
import { Space, Table, Tag } from "antd";
import { DATE_FORMAT } from "common/constants";
import { IFrame } from "components/iFrame";
import currency from "currency.js";
import { useFetchPaymentIntentsQuery } from "features/consumerPortalUpcomingPayments/paymentIntentsAPI";
import styled from "styled-components";
import { camelToWords, snakeToCamelCase } from "common/utils";

const StyledIFrame = styled(IFrame)`
  width: 100%;
  height: 75vh;
  border: 0;
`;

function PaymentHistory() {
  const { data: accounts = [], isLoading: isAccountsLoading } = useFetchPaymentIntentsQuery({});

  const columns = [
    {
      title: "Scheduled Date",
      dataIndex: "scheduledDate",
      key: "scheduledDate",
      render: (_, record) =>
        record.scheduledDate && moment(record.scheduledDate).format(DATE_FORMAT),
    },
    {
      title: "Account ID(s)",
      dataIndex: "accountExternalIds",
      key: "accountExternalIds",
      render: (_, record) => (record.accountExternalIds ?? []).join(", "),
    },
    {
      title: "Payment Method",
      dataIndex: "method",
      key: "method",
      render: (_, record) => {
        if (!record?.paymentMethod) {
          // NOTE: If the payment method is None, then we assume it was paid by a codebtor.
          return "Payment from co-debtor";
        }
        if (record?.paymentMethod.type === "process_card" || record?.paymentMethod.type === "ach") {
          return (
            <Space>
              {record?.paymentMethod?.type === "process_card" && (
                <span>{`Credit Card ****${record?.paymentMethod.cardLast4Digits}`}</span>
              )}
              {record?.paymentMethod?.type === "process_ach" && (
                <span>{`Bank Account ****${record?.paymentMethod.bankAccountLast4Digits}`}</span>
              )}
              {record?.refundedAt && <Tag color="blue">REFUNDED</Tag>}
              {record?.voidedAt && <Tag color="yellow">VOIDED</Tag>}
            </Space>
          );
        }

        return `Paid by ${camelToWords(snakeToCamelCase(record?.paymentMethod?.type))}`;
      },
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (_, record) => currency(record.totalAmount, { precision: 2 }).format(),
    },
    // {
    //   title: "",
    //   dataIndex: "action",
    //   key: "action",
    //   render: () => (
    //     <Space size="middle">
    //       <Button type="link">Cancel</Button>
    //     </Space>
    //   ),
    // },
  ];

  return (
    <>
      <h3>Payment History</h3>
      <Table
        scroll={{ x: "max-content" }}
        loading={isAccountsLoading}
        dataSource={accounts.filter((account) => account.executedAt)}
        columns={columns}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 5,
        }}
      />
    </>
  );
}

export default PaymentHistory;
function snakeToCamel(type) {
  throw new Error("Function not implemented.");
}
