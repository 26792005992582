import { Button, Popconfirm, Space, Table } from "antd";
import { DATE_FORMAT } from "common/constants";
import currency from "currency.js";
import {
  useDeletePaymentIntentMutation,
  useFetchPaymentIntentsQuery,
} from "features/consumerPortalUpcomingPayments/paymentIntentsAPI";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

export default function ConsumerPortalUpcomingPayments() {
  const { data: accounts = [], isLoading: isAccountsLoading } = useFetchPaymentIntentsQuery({
    scheduledDateStart: moment().add("day", 1).format(DATE_FORMAT),
  });

  const [deletePaymentIntent, { isLoading: isDeleteLoading }] = useDeletePaymentIntentMutation();

  const onPaymentCancel = (uuid) => {
    deletePaymentIntent({ paymentIntentUuids: [uuid] });
  };

  const columns = [
    {
      title: "Scheduled Date",
      dataIndex: "scheduledDate",
      key: "scheduledDate",
      render: (_, record) =>
        record.scheduledDate && moment(record.scheduledDate).format(DATE_FORMAT),
    },
    {
      title: "Account ID(s)",
      dataIndex: "accountExternalIds",
      key: "accountExternalIds",
      render: (_, record) => (record.accountExternalIds ?? []).join(", "),
    },
    {
      title: "Payment Method",
      dataIndex: "method",
      key: "method",
      render: (_, record) => {
        if (record?.paymentMethod?.type === "process_card") {
          return `Credit Card ****${record?.paymentMethod.cardLast4Digits}`;
        }
        if (record?.paymentMethod?.type === "process_bank_account") {
          return `Bank Account ****${record?.paymentMethod.bankAccountLast4Digits}`;
        }

        return "-";
      },
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (_, record) => currency(record.totalAmount, { precision: 2 }).format(),
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      render: (_, record) =>
        record.dataSource === "consumer_portal" ? (
          <Space size="middle">
            <Popconfirm
              title="Are you sure you want to cancel this payment?"
              onConfirm={() => onPaymentCancel(record.uuid)}
            >
              <Button type="link">Cancel</Button>
            </Popconfirm>
          </Space>
        ) : (
          <Link to="/contact">Contact Us</Link>
        ),
    },
  ];

  return (
    <>
      <h3>Upcoming Payments</h3>
      <Table
        scroll={{ x: "max-content" }}
        loading={isAccountsLoading || isDeleteLoading}
        // filter out accounts with a date that is today or in the future
        dataSource={accounts.filter(
          (account) =>
            moment(account.scheduledDate).isSameOrAfter(moment(), "day") && !account.executedAt,
        )}
        columns={columns}
      />
    </>
  );
}
