import { CreditCardOutlined } from "@ant-design/icons";
import { Divider, Flex, Form, Input, Radio, Select, Space } from "antd";
import { AktDatePicker } from "components/aktDatePicker";
import { useGetPaymentMethodsQuery } from "features/consumerPortalPaymentMethods/paymentMethodsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import MastercardLogo from "assets/mastercard.png";
import VisaLogo from "assets/visa.png";
import AmexLogo from "assets/amex.png";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { detectCardType } from "common/utils";
import { useState } from "react";

const StyledInput = styled(Input)`
  width: 225px;
`;

const StyledFlex = styled(Flex)`
  margin-left: 20px;
  margin-top: 8px;
`;

const StyledSelect = styled(Select)`
  width: 250px !important;
`;

const StyledVerticalFlex = styled(Flex)`
  border: ${(props) => (props.$selected ? "1px solid #7bb2ff !important" : "1px solid #f0f0f0")};
  padding: 16px;
  margin-bottom: 8px;
  width: 520px;
  &:hover {
    border-color: #d9d9d9;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
`;

const StyledDivider = styled(Divider)`
  font-size: 14px !important;
  margin-bottom: 0 !important;
`;

const StyledDiv = styled.div`
  span {
    text-transform: capitalize;
  }
`;

const StyledCreditCardImage = styled.img`
  height: 20px;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

function AddPaymentMethodForm({ prefix = "" }) {
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: paymentMethods, isLoading } = useGetPaymentMethodsQuery();
  const [cardNumber, setCardNumber] = useState("");
  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState("process_card");
  const disabledDate = (input) => {
    // Can not select days before today.
    return input && input < moment().startOf("day");
  };

  const billingDetails = (
    <>
      {/* <Form.Item label="Type" name={[prefix, "type"]}>
                            <StyledSelect
                              allowClear
                              placeholder="Type"
                              options={constants?.addressTypes.map(({ display, value }) => ({
                                value,
                                label: display,
                              }))}
                            />
                          </Form.Item> */}
      <Space size="small">
        <Form.Item label="Address" name={[prefix, "address_1"]} rules={[{ required: true }]}>
          <StyledInput maxLength={100} placeholder="Address" />
        </Form.Item>
        <Form.Item label=" " name={[prefix, "address_2"]}>
          <StyledInput maxLength={100} placeholder="Apt #, Suite, Floor (optional)" />
        </Form.Item>
      </Space>
      <Space size="large">
        <Form.Item label="City" name={[prefix, "city"]} rules={[{ required: true }]}>
          <Input maxLength={100} placeholder="City" />
        </Form.Item>
        <Form.Item label="State" name={[prefix, "state"]} rules={[{ required: true }]}>
          <Select
            popupMatchSelectWidth={false}
            placeholder="State"
            options={constants?.states.map(({ display, value }) => ({
              value,
              label: display,
            }))}
            filterOption={filterOption}
            showSearch
          />
        </Form.Item>
        <Form.Item
          name={[prefix, "zipCode"]}
          label="Zip Code"
          rules={[
            {
              required: true,
            },
            {
              pattern: /^\d{5}(-\d{4})?$/,
              message: "Must be a valid zip code",
            },
          ]}
        >
          <Input maxLength={5} placeholder="Zip Code" />
        </Form.Item>
      </Space>
    </>
  );

  return (
    <Form.Item
      name={[prefix, "paymentMethod"]}
      rules={[{ required: true, message: "A valid payment method is required" }]}
    >
      <StyledRadioGroup>
        <StyledVerticalFlex $selected={currentPaymentMethod === "process_card"} vertical>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prefix
                ? prevValues[prefix].paymentMethod !== currentValues[prefix].paymentMethod
                : prevValues.paymentMethod !== currentValues.paymentMethod
            }
          >
            {({ getFieldValue }) => {
              const paymentMethod = getFieldValue([prefix, "paymentMethod"]);
              setCurrentPaymentMethod(paymentMethod);

              return (
                <>
                  <Radio
                    style={{
                      fontWeight: paymentMethod === "process_card" ? "bold" : "normal",
                    }}
                    value="process_card"
                  >
                    Credit/Debit Card
                  </Radio>
                  {paymentMethod === "process_card" && (
                    <StyledFlex vertical>
                      <Form.Item
                        label="Card Number"
                        name={[prefix, "cardNumber"]}
                        rules={[{ required: true }]}
                      >
                        <Input
                          prefix={<CreditCardOutlined />}
                          value={cardNumber}
                          onChange={(e) => setCardNumber(e.target.value)}
                          placeholder="0000 0000 0000 0000"
                          suffix={
                            <>
                              <StyledCreditCardImage
                                src={MastercardLogo}
                                alt="mastercard"
                                disabled={detectCardType(cardNumber).type !== "MASTERCARD"}
                              />
                              <StyledCreditCardImage
                                src={VisaLogo}
                                alt="visa"
                                disabled={detectCardType(cardNumber).type !== "VISA"}
                              />
                              <StyledCreditCardImage
                                src={AmexLogo}
                                alt="amex"
                                disabled={detectCardType(cardNumber).type !== "AMEX"}
                              />
                            </>
                          }
                        />
                      </Form.Item>
                      <Space size="large">
                        <Form.Item
                          label="Expiration Date"
                          name={[prefix, "cardExpDate"]}
                          rules={[{ required: true }]}
                        >
                          <AktDatePicker
                            picker="month"
                            placeholder="MM/YY"
                            suffixIcon={null}
                            format="MM/YY"
                            disabledDate={disabledDate}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Security Code"
                          name={[prefix, "cvv"]}
                          rules={[{ required: true }]}
                        >
                          <StyledInput placeholder="CVV/CVC" />
                        </Form.Item>
                      </Space>
                      <Form.Item
                        label="Cardholder Name"
                        name={[prefix, "name"]}
                        rules={[{ required: true }]}
                      >
                        <StyledInput placeholder="Cardholder Name" />
                      </Form.Item>
                      <Form.Item>
                        <StyledDivider orientation="left">Billing Details</StyledDivider>
                      </Form.Item>
                      {billingDetails}
                    </StyledFlex>
                  )}
                </>
              );
            }}
          </Form.Item>
        </StyledVerticalFlex>

        <StyledVerticalFlex $selected={currentPaymentMethod === "process_ach"} vertical>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prefix
                ? prevValues[prefix].paymentMethod !== currentValues[prefix].paymentMethod
                : prevValues.paymentMethod !== currentValues.paymentMethod
            }
          >
            {({ getFieldValue }) => {
              const paymentMethod = getFieldValue([prefix, "paymentMethod"]);
              setCurrentPaymentMethod(paymentMethod);

              return (
                <>
                  <Radio
                    value="process_ach"
                    style={{
                      fontWeight: paymentMethod === "process_ach" ? "bold" : "normal",
                    }}
                  >
                    Bank Account ACH
                  </Radio>
                  {paymentMethod === "process_ach" && (
                    <StyledFlex vertical>
                      <Form.Item
                        label="Name on Account"
                        name={[prefix, "name"]}
                        rules={[{ required: true }]}
                      >
                        <StyledInput placeholder="Bankholder Name" />
                      </Form.Item>
                      <Form.Item
                        label="Account Type"
                        name={[prefix, "bankAccountType"]}
                        rules={[{ required: true }]}
                      >
                        <StyledSelect
                          popupMatchSelectWidth={false}
                          placeholder="Select one..."
                          options={[
                            {
                              value: "checking",
                              label: "Checking",
                            },
                            {
                              value: "savings",
                              label: "Savings",
                            },
                          ]}
                        />
                      </Form.Item>
                      <Space size="large">
                        <Form.Item
                          label="Bank Name"
                          name={[prefix, "bankAccountName"]}
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Bank Name" />
                        </Form.Item>
                        <Form.Item
                          label="Routing Number"
                          name={[prefix, "bankRoutingNumber"]}
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Routing Number" />
                        </Form.Item>
                      </Space>
                      <Space size="large">
                        <Form.Item
                          label="Account Number"
                          name={[prefix, "bankAccountNumber"]}
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Account Number" />
                        </Form.Item>
                        <Form.Item
                          label="Confirm Account Number"
                          name={[prefix, "confirmBankAccountNumber"]}
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Account Number" />
                        </Form.Item>
                      </Space>
                      <Form.Item>
                        <StyledDivider orientation="left">Account Holder Information</StyledDivider>
                      </Form.Item>
                      {billingDetails}
                    </StyledFlex>
                  )}
                </>
              );
            }}
          </Form.Item>
        </StyledVerticalFlex>
        {prefix && (
          <StyledVerticalFlex $selected={currentPaymentMethod === "existingPaymentMethod"} vertical>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues[prefix].paymentMethod !== currentValues[prefix].paymentMethod
              }
            >
              {({ getFieldValue }) => {
                const paymentMethod = getFieldValue([prefix, "paymentMethod"]);
                setCurrentPaymentMethod(paymentMethod);
                return (
                  <>
                    <Radio
                      value="existingPaymentMethod"
                      style={{
                        fontWeight: paymentMethod === "existingPaymentMethod" ? "bold" : "normal",
                      }}
                    >
                      Existing Payment Method
                    </Radio>
                    {paymentMethod === "existingPaymentMethod" && (
                      <StyledFlex vertical>
                        {paymentMethods?.length > 0 ? (
                          <Form.Item
                            name="paymentMethodUuid"
                            label="Payment Method"
                            rules={[{ required: true, message: "Please select a payment method" }]}
                          >
                            <StyledSelect
                              allowClear
                              popupMatchSelectWidth={false}
                              placeholder="Select Payment Method"
                              loading={isLoading}
                              options={paymentMethods?.map((method) => ({
                                value: method.uuid,
                                label:
                                  method.type === "process_ach" ? (
                                    <StyledDiv>
                                      <span>{method.bankAccountType}</span> account ••••
                                      {method.bankAccountLast4Digits}
                                    </StyledDiv>
                                  ) : (
                                    `Card ending in •••• ${method.cardLast4Digits}`
                                  ),
                              }))}
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item>
                            <span>
                              No payment methods found. Please add a{" "}
                              <Link to="/payments/payment-methods">payment method</Link> first.
                            </span>
                          </Form.Item>
                        )}
                      </StyledFlex>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </StyledVerticalFlex>
        )}
      </StyledRadioGroup>
    </Form.Item>
  );
}

export default AddPaymentMethodForm;
