import PasswordResetForm from "features/auth/components/passwordResetForm";
import LoginPageLayout from "layouts/consumerPortal/loginPageLayout";

function PasswordReset() {
  return (
    <LoginPageLayout>
      <PasswordResetForm />
    </LoginPageLayout>
  );
}

export default PasswordReset;
