import { createSlice } from "@reduxjs/toolkit";
import { DATE_FORMAT } from "common/constants";
import currency from "currency.js";
import moment from "moment-timezone";
import { paymentsApi } from "features/consumerPortalPaymentPage/paymentsAPI";

export const PAYMENT_VIEW_SCREEN_ENUMS = {
  SelectAccountsForPayment: "selectAccountsForPayment",
  SetupPaymentPlanConfiguration: "setupPaymentPlanConfiguration",
  SetupFullPayment: "setupFullPayment",
  SetupPartialPayment: "setupPartialPayment",
  // NOTE: These currently point to the same component, but we need the separation to properly keep track of previous and next steps.
  SelectPaymentMethod: "selectPaymentMethod",
  SelectPaymentOption: "selectPaymentOption",
  PaymentConfirmation: "paymentConfirmation",
};

export const initialState = {
  // Shared state fields
  // ------------------------------
  selectedAccounts: [],
  isZeroBalanceAccountVisible: false,
  isInterestApplicable: false, // If any account accrues interest, this will be set to true. This is used to determine if we need to call the backend to recalculate the balance when dates/amounts are paid.
  originalTotalAmount: null, // amount due across selected accounts
  // totalAmountDueAsOfStartDateWithInterest is the amount due across selected accounts accounting for interest based off the start date.
  // This value is calculated by the sum of the calculate balance history response from balanceResponse.
  totalAmountDueAsOfStartDateWithInterest: null,
  partialPaymentAmount: null, // If a partial payment is flagged, this will be non-empty
  totalPaymentAmount: null, // The total payment amount. NOTE: This value is only available on and after the OneTimePaymentSchedule.
  isPaymentPlan: false, // defaults to one time payment, else it's a payment plan
  isNewPaymentMethod: true,
  startDate: moment().startOf("day").format(DATE_FORMAT), // Start of first payment. For one time payments, will be set to the payment date.

  // One Time Payments state fields
  // ------------------------------

  // full - Payment covers all selected account balances.
  // partial - Payment was made, but not the entire full amount.
  // settled - Payment covers all selected account balance, but payment is a settled amount (less than the full).
  paymentIntentType: "full", // [full, partial, settled]

  // Payment Plan state fields
  // ------------------------------
  numberOfPayments: 1, // Only applicable if it's a payment plan
  paymentFrequency: "monthly", // ["monthly", "weekly, biweekly", "bimonthly"]
  recurringAmount: null,
  downPayment: 0,
  balloonPayment: 0,

  // NOTE: The paymentsSchedule is a list of payments that will be made.
  // [payment.index]: {
  //   totalAmount: payment.amount,
  //   date: payment.date,
  // },
  paymentsSchedule: [],
  isIncludedWithLastPayment: true,

  balanceResponse: undefined, // The response from the backend when we calculate the account balance which includes interest from the payment schedule.

  // Payment Method Screen
  paymentMethodOptions: undefined,
  currentView: PAYMENT_VIEW_SCREEN_ENUMS.SelectAccountsForPayment, // Defaults to first view in the Payments tab.
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    // Shared Reducers
    // ------------------------------
    setSelectedAccounts: (state, action) => {
      state.selectedAccounts = action.payload;
      state.isInterestApplicable = state.selectedAccounts.some(
        (account) => account.interestRate > 0,
      );
      state.originalTotalAmount = state.selectedAccounts.reduce(
        (total, account) => total.add(account.totalBalance),
        currency(0, { precision: 2 }),
      ).value;
    },
    setPaymentIntentType: (state, action) => {
      state.paymentIntentType = action.payload;
    },

    // One Time Payment Reducers
    // ------------------------------
    setAsOneTimeFullPayment: (state, action) => {
      const { paymentIntentType } = action.payload;
      state.isPaymentPlan = false;
      state.paymentIntentType = paymentIntentType;
    },
    setPaymentScheduleDateChange: (state, action) => {
      const { paymentIndex, date } = action.payload;
      const index = state.paymentsSchedule.findIndex((payment) => payment.index === paymentIndex);
      state.startDate = date;
      state.paymentsSchedule[index] = {
        ...state.paymentsSchedule[index],
        date,
      };
    },
    setupAsOneTimePayment: (state, action) => {
      const { paymentIntentType, partialPaymentAmount, originalTotalAmount, totalPaymentAmount } =
        action.payload;
      state.paymentIntentType = paymentIntentType;
      if (paymentIntentType === "full") {
        state.partialPaymentAmount = null;
      } else {
        state.partialPaymentAmount = partialPaymentAmount;
      }
      state.isPaymentPlan = false;
      state.originalTotalAmount = originalTotalAmount;
      state.totalPaymentAmount = totalPaymentAmount;
      // Clear out any values that are only used for Payment Plans.
      state.downPayment = null;
    },

    // Payment Plan Reducers
    // ------------------------------
    setupAsInitialPaymentPlan: (state, action) => {
      // Set the payment configuration mode to payment plan, and reset all the defaults.
      // NOTE: This is different from setPaymentPlanConfiguration, in that setPaymentPlanConfiguration should
      // be used if any of the variables change. This is only used to set the initial defaults.
      // We may have a situation in the future where they want to "keep the values they've entered", instead of
      // always resetting to the defaults.
      const { originalTotalAmount, totalPaymentAmount } = action.payload;
      state.partialPaymentAmount = null;
      state.originalTotalAmount = originalTotalAmount;
      state.totalPaymentAmount = totalPaymentAmount;
      state.paymentsSchedule = [];
      state.startDate = moment().format(DATE_FORMAT);
      state.isPaymentPlan = true;
      state.numberOfPayments = 1;
      state.downPayment = 0;
      state.paymentFrequency = "monthly";
      state.recurringAmount = state.originalTotalAmount;
      state.balloonPayment = 0;
      state.isIncludedWithLastPayment = true;
    },
    setPaymentPlanConfiguration: (state, action) => {
      const {
        downPayment,
        recurringAmount,
        numberOfPayments,
        frequency,
        paymentsSchedule,
        totalPaymentAmount,
        balloonPayment,
      } = action.payload;
      state.isPaymentPlan = true; // Redundant, but adding this here.
      if (paymentsSchedule) {
        state.paymentsSchedule = paymentsSchedule;
        state.startDate = state.paymentsSchedule[0].date;
      } else {
        state.paymentsSchedule = [];
        state.startDate = moment().format(DATE_FORMAT);
      }
      state.numberOfPayments = numberOfPayments;
      state.recurringAmount = recurringAmount;
      state.downPayment = downPayment;
      state.paymentFrequency = frequency;
      state.totalPaymentAmount = totalPaymentAmount;
      state.balloonPayment = balloonPayment;
    },
    setIsZeroBalanceAccountVisibleOption: (state, action) => {
      state.isZeroBalanceAccountVisible = action.payload;
    },
    setPaymentFrequency: (state, action) => {
      state.paymentFrequency = action.payload;
    },
    setPaymentsSchedule: (state, action) => {
      const { paymentsSchedule, totalAmountDueAsOfStartDateWithInterest } = action.payload;
      state.paymentsSchedule = paymentsSchedule;
      state.startDate = state.paymentsSchedule[0].date;
      state.totalPaymentAmount = state.paymentsSchedule.reduce(
        (total, payment) => total.add(payment.amount),
        currency(0, { precision: 2 }),
      ).value;
      // If the amount due hasn't been affected by interest, then keep it the same value.
      // In general, whenever the current payment schedule is updated, the totalAmountDueAsOfStartDateWithInterest
      // will update if the interest changes to track the new total amount due as of the start date as to account for interest.
      // Thus, totalAmountDueAsOfStartDateWithInterest should always track the latest amount due.
      state.totalAmountDueAsOfStartDateWithInterest =
        totalAmountDueAsOfStartDateWithInterest ?? state.totalAmountDueAsOfStartDateWithInterest;
    },
    setDownPayment: (state, action) => {
      const { downPayment, recurringAmount, balloonPayment, isIncludedWithLastPayment } =
        action.payload;
      state.downPayment = downPayment;
      state.recurringAmount = recurringAmount;
      state.balloonPayment = balloonPayment;
      state.isIncludedWithLastPayment = isIncludedWithLastPayment;
    },
    setNumberOfPaymentsChange: (state, action) => {
      const { numberOfPayments, recurringAmount, balloonPayment, isIncludedWithLastPayment } =
        action.payload;
      state.numberOfPayments = numberOfPayments;
      state.recurringAmount = recurringAmount;
      state.balloonPayment = balloonPayment;
      state.isIncludedWithLastPayment = isIncludedWithLastPayment;
    },
    setRecurringAmountChange: (state, action) => {
      const { recurringAmount, numberOfPayments, balloonPayment, isIncludedWithLastPayment } =
        action.payload;
      state.recurringAmount = recurringAmount;
      state.numberOfPayments = numberOfPayments;
      state.balloonPayment = balloonPayment;
      state.isIncludedWithLastPayment = isIncludedWithLastPayment;
    },
    setIsIncludedWithLastPaymentChange: (state, action) => {
      state.isIncludedWithLastPayment = action.payload;
    },
    setBalloonPayment: (state, action) => {
      state.balloonPayment = action.payload;
    },
    setRecurringAmount: (state, action) => {
      state.recurringAmount = action.payload;
    },
    setAsPaymentPlan: (state) => {
      state.isPaymentPlan = true;
    },
    setIsNewPaymentMethodOption: (state, action) => {
      state.isNewPaymentMethod = action.payload;
    },
    setCurrentView: (state, action) => {
      state.currentView = action.payload;
    },
    resetMakeAPayment: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      paymentsApi.endpoints.calculateBalanceFromSchedule.matchFulfilled,
      (state, { payload }) => {
        state.balanceResponse = payload.results;
      },
    );
  },
});

export const {
  setAsPaymentPlan,
  setAsOneTimeFullPayment,
  setPaymentScheduleDateChange,
  setSelectedAccounts,
  setPaymentIntentType,
  setupAsOneTimePayment,
  setupAsInitialPaymentPlan,
  setPaymentPlanConfiguration,
  setIsZeroBalanceAccountVisibleOption,
  setDownPayment,
  setNumberOfPaymentsChange,
  setRecurringAmountChange,
  setBalloonPayment,
  setRecurringAmount,
  setPaymentFrequency,
  setIsNewPaymentMethodOption,
  resetMakeAPayment,
  setIsIncludedWithLastPaymentChange,
  setPaymentsSchedule,
  setCurrentView,
} = paymentsSlice.actions;

export const selectPaymentsSlice = (state) => state.payments;

export default paymentsSlice.reducer;
