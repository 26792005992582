import {
  ExportOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Dropdown, Layout, Modal, message, theme } from "antd";
import CompanyLogo from "assets/aktos-logo-1004x923.png";
import config from "app/envConfig";
import { resetStateAction } from "common/redux/resetStateAction";
import { useFetchMeQuery } from "features/auth/authAPI";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useWhiteLabel } from "providers/whiteLabelProvider";

const { Header } = Layout;

const StyledLogo = styled("img")`
  margin-left: 8px;
  cursor: pointer;
`;

const StyledCaret = styled.div`
  margin-left: 6px;
  margin-right: 16px;
  font-size: 16px;
  margin-top: -4px;
  transform: rotate(180deg);
`;

const StyledName = styled.span`
  text-transform: capitalize;
`;

const StyledAvatar = styled(Avatar)`
  vertical-align: middle;
  margin-right: 4px;
`;

const StyledMenu = styled("span")`
  user-select: none;
  cursor: pointer;
`;

const StyledProfileMenu = styled(StyledMenu)`
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledHeader = styled(Header)`
  min-width: 470px;
  z-index: 10;
  position: fixed;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 470px) {
    display: none;
  }
  &&& {
    padding-inline: 0;
  }
`;

const StyledUserActions = styled.div`
  float: right;
  @media only screen and (max-width: 320px) {
    background: #fff;
    color: #404fc7;
  }
  &:hover {
    color: gray;
    .ant-avatar {
      background: lightgray;
    }
  }
`;

function DashboardHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: currentUser } = useFetchMeQuery();
  const { logout } = useAuth0();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { isWhiteLabeled, logo } = useWhiteLabel();

  const onLogout = () => {
    const logoutModal = Modal.confirm({
      title: "Logout",
      content: "Are you sure you want to log out?",
      icon: <LogoutOutlined />,
      onCancel: () => {
        /** No need to define body */
      },
      onOk: async () => {
        logoutModal.update({
          okButtonProps: { disabled: true },
          cancelButtonProps: { disabled: true },
        });

        await dispatch(resetStateAction());
        if (!config.useAuth0) {
          navigate("/login");
          message.success("Logged out successfully.", 2);
          return;
        }

        logout({
          logoutParams: {
            returnTo: `${window.location.origin}/login`,
          },
        });
        message.success("Logged out successfully.", 2);
      },
    });
  };

  const onChangePassword = () => {
    navigate("/change-password");
  };

  const items = [
    {
      key: "profile",
      icon: <UserOutlined />,
      label: "View Profile",
      actionHandler: () => {
        navigate("/profile");
      },
    },
    {
      key: "changePassword",
      icon: <UnlockOutlined />,
      label: "Change Password",
      actionHandler: onChangePassword,
    },
    {
      key: "contactUs",
      icon: <QuestionCircleOutlined />,
      label: "Help",
      actionHandler: () => {
        navigate("/contact");
      },
    },
    {
      key: "logout",
      icon: <ExportOutlined />,
      label: "Log Out",
      actionHandler: onLogout,
    },
  ];

  const onDropdownClick = ({ item, key, keyPath, domEvent }) => {
    // This prevents navigation to invoice detail when clicking on a dropdown item
    domEvent.preventDefault();
    domEvent.stopPropagation();

    // Call the action handler defined in the items dictionary
    item.props.actionHandler();
  };

  return (
    <StyledHeader
      style={{
        padding: 0,
        background: colorBgContainer,
      }}
    >
      <StyledLogo src={isWhiteLabeled ? logo : CompanyLogo} onClick={() => navigate("/accounts")} />
      <StyledUserActions>
        {/* <StyledSettingsMenu>
          <Badge count={10} dot>
            <BellOutlined style={{ fontSize: 18, color: "#000" }} />
          </Badge>
        </StyledSettingsMenu> */}
        {/* <Divider type="vertical" /> */}
        <Dropdown
          menu={{
            // @ts-ignore
            items,
            onClick: (clickHandlerProp) => onDropdownClick(clickHandlerProp),
          }}
          trigger={["click"]}
        >
          <StyledProfileMenu>
            <StyledAvatar shape="circle" size="small" icon={<UserOutlined />} />
            {currentUser?.name && <StyledName>{currentUser.name.toLowerCase()}</StyledName>}
            <StyledCaret>^</StyledCaret>
          </StyledProfileMenu>
        </Dropdown>
      </StyledUserActions>
    </StyledHeader>
  );
}

export default DashboardHeader;
