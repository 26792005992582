import { UserOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Typography } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { resetStateAction } from "common/redux/resetStateAction";
import { useConsumerForgotPasswordMutation, useLazyFetchMeQuery } from "features/auth/authAPI";
import { selectIsAccessTokenStoredInRtk } from "features/auth/authSlice";
import { useWhiteLabel } from "providers/whiteLabelProvider";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
`;

const StyledDiv = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const StyledHeading = styled.h2`
  margin-top: 0;
  margin-bottom: 4px;
  text-align: center;
`;

const StyledCol = styled(Col)`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 48px;
  background: #fff;
  border-radius: 10px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100% - 90px);
  position: absolute;
  background: #f7f7f7;
  padding-bottom: 24px;
`;

function ForgotPasswordForm() {
  const { slug } = useWhiteLabel();
  const dispatch = useDispatch();
  const [forgotPassword, { isLoading }] = useConsumerForgotPasswordMutation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const [fetchMe] = useLazyFetchMeQuery();
  const isAccessTokenStoredInRtk = useSelector(selectIsAccessTokenStoredInRtk);

  // Reset state when user navigates to login page
  useEffect(() => {
    if (isAccessTokenStoredInRtk) {
      dispatch(resetStateAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values) => {
    const { email } = values;

    const result = await forgotPassword({ email, agencySlug: slug });
    if ("data" in result) {
      await navigate("/password-reset");
      message.success("Sent password reset instructions.", 2);
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  return (
    <StyledContainer>
      <StyledCol>
        <StyledHeading>Reset Password</StyledHeading>
        <Typography.Text type="secondary">
          Enter your login email for instructions to reset your password
        </Typography.Text>
        <StyledForm
          onFinish={onFinish}
          className="login-form"
          initialValues={{
            email: "",
            password: "",
          }}
          form={form}
        >
          <Form.Item
            name="email"
            hasFeedback
            rules={[
              { required: true, message: "Please enter email" },
              {
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" data-testid="email" />
          </Form.Item>

          <Form.Item>
            <StyledButton
              type="primary"
              htmlType="submit"
              className="login-form-button"
              data-testid="login-button"
              loading={isLoading}
            >
              Send Email
            </StyledButton>
          </Form.Item>
        </StyledForm>
        <StyledDiv>
          <Link to="/login">Back to Log In</Link>
        </StyledDiv>
      </StyledCol>
    </StyledContainer>
  );
}

export default ForgotPasswordForm;
