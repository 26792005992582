import { ConsumerPortalUpcomingPayments } from "features/consumerPortalUpcomingPayments";
import { ConsumerPortalPaymentHistory } from "features/consumerPortalPaymentHistory";
import { Space } from "antd";

export default function UpcomingPaymentsPage() {
  return (
    <Space
      style={{
        display: "flex",
      }}
      direction="vertical"
    >
      <ConsumerPortalUpcomingPayments />
      <ConsumerPortalPaymentHistory />
    </Space>
  );
}
