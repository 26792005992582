import { ArrowRightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Popover, Row, Table } from "antd";
import { formatCurrency } from "common/utils";
import currency from "currency.js";
import { useGetConsumerPortalAccountsQuery } from "features/consumerPortalDashboard/accountsAPI";
import {
  PAYMENT_VIEW_SCREEN_ENUMS,
  selectPaymentsSlice,
  setCurrentView,
  setSelectedAccounts,
} from "features/consumerPortalPaymentPage/paymentsSlice";
import Content from "layouts/consumerPortal/content";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const StyledDiv = styled.div`
  margin-top: 24px;
  margin-left: 16px;
  & span {
    margin-left: 12px;
  }
`;
const StyledInfoValue = styled.div`
  margin-left: 4px;
  margin-right: 4px;
`;
const StyledButton = styled(Button)`
  margin-top: 24px;
  float: right;
`;

function SelectAccounts() {
  const { data: accounts, isLoading } = useGetConsumerPortalAccountsQuery();
  const dispatch = useDispatch();
  const paymentsSlice = useSelector(selectPaymentsSlice);

  const onSelectedAccountChange = (_, selectedAccounts) => {
    dispatch(setSelectedAccounts(selectedAccounts));
  };

  const rowSelection = {
    selectedRowKeys: paymentsSlice.selectedAccounts.map((row) => row.uuid),
    onChange: onSelectedAccountChange,
    preserveSelectedRowKeys: true,
  };

  const onContinue = () => {
    dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.SelectPaymentOption));
  };

  const columns = [
    { title: "Creditor", dataIndex: "currentCreditorName", key: "currentCreditorName" },
    { title: "Account Number", dataIndex: "externalId", key: "externalId" },
    {
      title: "Current Balance",
      dataIndex: "totalBalance",
      key: "totalBalance",
      render: (text, record) => {
        const balanceItems = [
          {
            key: "principal",
            label: "Principal",
            value: formatCurrency(record.principalBalance),
          },
          {
            key: "interest",
            label: `Interest (${
              currency(record.interestRate, { precision: 4 }).multiply(100).value
            }%)`,
            value: formatCurrency(record.interestBalance),
          },
          {
            key: "totalFees",
            label: "Fees",
            value: formatCurrency(record.totalFeesBalance),
          },
          // {
          //   key: "originalTotal",
          //   label: <strong>Total</strong>,
          //   value: <strong>{formatCurrency(text)}</strong>,
          // },
        ];
        const totalBalanceContent = (
          <Col>
            <Descriptions bordered column={1} size="small">
              {balanceItems.map((item) => (
                <Descriptions.Item key={item.key} label={item.label}>
                  {item.value}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Col>
        );
        return (
          <Row align="middle">
            <StyledInfoValue>{formatCurrency(text)}</StyledInfoValue>
            <Popover
              content={totalBalanceContent}
              title="Current Balance Breakdown"
              trigger="click"
              placement="right"
            >
              <InfoCircleOutlined />
            </Popover>
          </Row>
        );
      },
    },
  ];

  return (
    <Content>
      <h3>Payment Accounts</h3>

      <Card>
        <Table
          pagination={false}
          // Only show non-$0, active (not closed) accounts.
          // NOTE: Accounts are marked as $0 balance on the backend when they are closed / settled / paid in full.
          dataSource={accounts?.filter(
            (account) => currency(account.totalBalance, { precision: 2 }).value > 0,
          )}
          columns={columns}
          loading={isLoading}
          rowSelection={rowSelection}
          rowKey="uuid"
        />
        <StyledDiv>
          <strong>Total Balance Due</strong>
          <span>{currency(paymentsSlice.originalTotalAmount, { precision: 2 }).format()}</span>
        </StyledDiv>
      </Card>
      <StyledButton
        type="primary"
        icon={<ArrowRightOutlined />}
        onClick={onContinue}
        disabled={paymentsSlice.selectedAccounts.length <= 0}
      >
        Next: Choose Payment Option
      </StyledButton>
    </Content>
  );
}

export default SelectAccounts;
