import { ArrowLeftOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import { DATE_FORMAT } from "common/constants";
import { capitalizeFirstLetter, debounce, formatCurrency } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import currency from "currency.js";
import { useCreatePaymentIntentMutation } from "features/consumerPortalUpcomingPayments/paymentIntentsAPI";
import AddPaymentMethodForm from "features/consumerPortalPaymentMethods/components/addPaymentMethodForm";
import { useCalculateBalanceFromScheduleMutation } from "features/consumerPortalPaymentPage/paymentsAPI";
import {
  PAYMENT_VIEW_SCREEN_ENUMS,
  selectPaymentsSlice,
  setCurrentView,
  setPaymentFrequency,
  setPaymentsSchedule,
  setRecurringAmountChange,
} from "features/consumerPortalPaymentPage/paymentsSlice";
import {
  getNextDate,
  getPaymentsForPaymentPlanSchedule,
  getPaymentsScheduleInfoWithInterest,
} from "features/payments/paymentDefaults";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const StyledCard = styled(Card)`
  margin-bottom: 12px;
  margin-right: 12px;
  width: 570px;
  .ant-card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-card-head {
    border-bottom: none;
  }
`;

const StyledPaymentSummaryCard = styled(Card)`
  min-width: 570px;
  min-height: 380px;
`;

const StyledButton = styled(Button)`
  margin-top: 12px;
  float: right;
`;

const StyledDiv = styled(Flex)`
  margin-top: 12px;
  flex-direction: row-reverse;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 220px;
`;

const StyledScheduleButton = styled(Button)`
  padding: 0;
  font-size: 13px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

const StyledValue = styled.span`
  overflow-wrap: anywhere;
  font-size: 13px;
  color: grey;
`;

const StyledLabel = styled.span`
  font-size: 13px;
  flex: 1;
`;

const StyledPayButton = styled(Button)`
  height: 48px;
`;

const StyledForm = styled(Form)`
  margin-top: 16px;
`;

function PaymentPlan() {
  const [form] = Form.useForm();
  const [processPayment, { isLoading: isPosting }] = useCreatePaymentIntentMutation();
  const dispatch = useDispatch();
  const paymentsSlice = useSelector(selectPaymentsSlice);
  const [isPlanPreviewModalVisible, setPlanPreviewModalVisible] = useState(false);
  const [
    calculateBalanceFromSchedule,
    { isLoading: isCalculateBalanceFromScheduleLoading, error },
  ] = useCalculateBalanceFromScheduleMutation();

  const handleBalloonPayment = (amountToDistribute, numberOfPayments, recurringAmount) => {
    amountToDistribute = currency(amountToDistribute, { precision: 4 });
    numberOfPayments = currency(numberOfPayments, { precision: 4 });
    recurringAmount = currency(recurringAmount, { precision: 4 });
    const balloonPaymentAmount = amountToDistribute.subtract(
      numberOfPayments.multiply(recurringAmount),
    );
    return balloonPaymentAmount.value;
  };

  const changePaymentDate = async (date) => {
    const paymentsSchedule = getPaymentsForPaymentPlanSchedule({
      accountIds: null,
      accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
      downPayment: paymentsSlice.downPayment,
      recurringAmount: paymentsSlice.recurringAmount,
      balloonPayment: paymentsSlice.balloonPayment,
      numberOfPayments: paymentsSlice.numberOfPayments,
      startDate: date,
      frequency: paymentsSlice.paymentFrequency,
      isIncludedWithLastPayment: false,
    });

    const paymentsScheduleInfoWithInterest = await getPaymentsScheduleInfoWithInterest({
      isInterestApplicable: paymentsSlice.isInterestApplicable,
      paymentsScheduleWithoutInterest: paymentsSchedule,
      totalAmountDueAsOfStartDateWithoutInterest:
        paymentsSlice.totalAmountDueAsOfStartDateWithoutInterest,
      calculateBalanceFromSchedule,
    });
    dispatch(
      setPaymentsSchedule({
        paymentsSchedule: paymentsScheduleInfoWithInterest.paymentsSchedule,
        totalAmountDueAsOfStartDateWithInterest:
          paymentsScheduleInfoWithInterest.totalAmountDueAsOfStartDateWithInterest,
      }),
    );
  };

  const onPaymentFrequencyChange = async (paymentFrequency) => {
    const paymentsSchedule = getPaymentsForPaymentPlanSchedule({
      accountIds: null,
      accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
      downPayment: paymentsSlice.downPayment,
      recurringAmount: paymentsSlice.recurringAmount,
      balloonPayment: paymentsSlice.balloonPayment,
      numberOfPayments: paymentsSlice.numberOfPayments,
      startDate: paymentsSlice.startDate,
      frequency: paymentFrequency,
      isIncludedWithLastPayment: false,
    });

    const paymentsScheduleInfoWithInterest = await getPaymentsScheduleInfoWithInterest({
      isInterestApplicable: paymentsSlice.isInterestApplicable,
      paymentsScheduleWithoutInterest: paymentsSchedule,
      totalAmountDueAsOfStartDateWithoutInterest: paymentsSlice.isInterestApplicable
        ? paymentsSlice.totalAmountDueAsOfStartDateWithInterest
        : paymentsSlice.originalTotalAmount,
      calculateBalanceFromSchedule,
    });
    batch(() => {
      dispatch(
        setPaymentsSchedule({
          paymentsSchedule: paymentsScheduleInfoWithInterest.paymentsSchedule,
          totalAmountDueAsOfStartDateWithInterest:
            paymentsScheduleInfoWithInterest.totalAmountDueAsOfStartDateWithInterest,
        }),
      );
      dispatch(setPaymentFrequency(paymentFrequency));
    });
  };

  const onFinish = async ({
    startDate,
    paymentMethodUuid,
    newPaymentMethod,
    recurringAmount,
    frequency,
  }) => {
    const { totalPaymentAmount } = paymentsSlice;
    const numberOfPayments = Math.floor(
      currency(totalPaymentAmount, { precision: 2 }).divide(recurringAmount).value,
    );
    const balloonPayment = currency(totalPaymentAmount, { precision: 2 }).subtract(
      currency(recurringAmount, { precision: 2 }).multiply(numberOfPayments),
    ).value;

    const formattedStartDate = moment(startDate, DATE_FORMAT);

    const intents = Array.from({ length: numberOfPayments }, (_, index) => {
      const currentDate = getNextDate({
        startDate: formattedStartDate,
        frequency,
        currentPaymentIndex: index,
      });
      const intent = {
        totalAmount:
          index === numberOfPayments - 1
            ? currency(recurringAmount, { precision: 2 }).add(balloonPayment).value
            : recurringAmount,
        scheduledDate: currentDate.format(DATE_FORMAT),
        paymentMethodUuid,
        accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
        paymentIntentType: "full",
        isPaymentPlan: true,
      };
      return intent;
    });
    const newPaymentMethodFields = {};
    if (!paymentMethodUuid) {
      const { paymentMethod, ...newPaymentMethodValues } = newPaymentMethod;
      const [firstName, lastName] = (newPaymentMethodValues.name ?? "").split(" ");
      newPaymentMethodValues.firstName = firstName;
      newPaymentMethodValues.lastName = lastName;

      if (paymentMethod === "process_card") {
        // post-transformation for credit card
        newPaymentMethodValues.cardExpMonth = moment(
          newPaymentMethodValues.cardExpDate,
          "MM/YY",
        ).format("MM");
        newPaymentMethodValues.cardExpYear = moment(
          newPaymentMethodValues.cardExpDate,
          "MM/YY",
        ).format("YYYY");
        newPaymentMethodFields.newCreditCard = newPaymentMethodValues;
      }
      if (paymentMethod === "process_ach") {
        newPaymentMethodFields.newAch = newPaymentMethodValues;
      }
    }
    const result = await processPayment({
      intents,
      ...newPaymentMethodFields,
      newPaymentMethod,
      paymentIntentType: "full",
      paymentMethodUuid,
      paymentMethodType: paymentMethodUuid ? null : newPaymentMethod.paymentMethod,
      executeIfReady: true,
    });
    if ("data" in result) {
      dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.PaymentConfirmation));
    }
    if ("error" in result) {
      message.error("Failed to process payment");
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return (current && current >= moment().add(30, "day")) || current < moment().startOf("day");
  };

  const initialValues = {
    recurringAmount: paymentsSlice.totalPaymentAmount,
    frequency: paymentsSlice.paymentFrequency,
    startDate: paymentsSlice.startDate,
    isNewPaymentMethod: false,
    newPaymentMethod: {
      // paymentMethod: "process_card") {,
    },
  };

  const handleNumberOfPayments = (amountToDistribute, recurringAmount) => {
    amountToDistribute = currency(amountToDistribute, { precision: 4 });
    const numberOfPayments = Math.floor(amountToDistribute.divide(recurringAmount).value);
    return numberOfPayments;
  };

  const onChangeRecurringAmount = debounce(async (newRecurringAmount) => {
    const { downPayment, originalTotalAmount } = paymentsSlice;
    if (newRecurringAmount === null) {
      return;
    }

    // TODO: This is wrong, should take into account interest.
    if (newRecurringAmount > originalTotalAmount) {
      return;
    }
    let isIncludedWithLastPayment = false;
    const amountToDistribute = currency(paymentsSlice.totalPaymentAmount, {
      precision: 4,
    }).subtract(downPayment).value;
    const numberOfPayments = handleNumberOfPayments(amountToDistribute, newRecurringAmount);
    const balloonPayment = handleBalloonPayment(
      amountToDistribute,
      numberOfPayments,
      newRecurringAmount,
    );
    if (newRecurringAmount !== null) {
      form.setFieldsValue({ numberOfPayments, balloonPayment });
      if (balloonPayment === 0) {
        form.setFieldsValue({ isIncludedWithLastPayment: false });
        isIncludedWithLastPayment = false;
      }
    }

    const paymentsSchedule = getPaymentsForPaymentPlanSchedule({
      accountIds: null,
      accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
      downPayment: paymentsSlice.downPayment,
      recurringAmount: newRecurringAmount,
      balloonPayment,
      numberOfPayments,
      startDate: paymentsSlice.startDate,
      frequency: paymentsSlice.paymentFrequency,
      isIncludedWithLastPayment: false,
    });

    const paymentsScheduleInfoWithInterest = await getPaymentsScheduleInfoWithInterest({
      isInterestApplicable: paymentsSlice.isInterestApplicable,
      paymentsScheduleWithoutInterest: paymentsSchedule,
      totalAmountDueAsOfStartDateWithoutInterest:
        paymentsSlice.totalAmountDueAsOfStartDateWithoutInterest,
      calculateBalanceFromSchedule,
    });
    batch(() => {
      dispatch(
        setPaymentsSchedule({
          paymentsSchedule: paymentsScheduleInfoWithInterest.paymentsSchedule,
          totalAmountDueAsOfStartDateWithInterest:
            paymentsScheduleInfoWithInterest.totalAmountDueAsOfStartDateWithInterest,
        }),
      );
      dispatch(
        setRecurringAmountChange({
          recurringAmount: newRecurringAmount,
          numberOfPayments,
          balloonPayment,
          isIncludedWithLastPayment,
        }),
      );
    });
  }, 1000);

  const onPrevious = () => {
    dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.SelectPaymentOption));
  };

  const recurringNumberOfPayments = useMemo(() => {
    return !paymentsSlice.isIncludedWithLastPayment && paymentsSlice.balloonPayment > 0
      ? paymentsSlice.numberOfPayments + 1
      : paymentsSlice.numberOfPayments;
  }, [
    paymentsSlice.isIncludedWithLastPayment,
    paymentsSlice.balloonPayment,
    paymentsSlice.numberOfPayments,
  ]);

  return (
    <>
      <StyledForm form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues}>
        <Flex>
          <Col>
            <StyledCard title="Set up Payment Plan">
              <Space>
                <Form.Item
                  label="Recurring Amount"
                  name="recurringAmount"
                  rules={[
                    { required: true, message: "Please enter an amount" },
                    {
                      type: "number",
                      max: paymentsSlice.isInterestApplicable
                        ? paymentsSlice.totalAmountDueAsOfStartDateWithInterest
                        : paymentsSlice.originalTotalAmount,
                      message: paymentsSlice.isInterestApplicable
                        ? `Please enter a maximum of $${paymentsSlice.totalAmountDueAsOfStartDateWithInterest}`
                        : `Please enter a maximum of $${paymentsSlice.originalTotalAmount}`,
                    },
                    {
                      type: "number",
                      min: 20,
                      message: "Please enter a minimum of $20.00",
                    },
                    {
                      required: recurringNumberOfPayments > 500,
                      message: "Cannot exceed 500 recurring payments",
                    },
                  ]}
                >
                  <StyledInputNumber
                    onChange={onChangeRecurringAmount}
                    placeholder="Enter a minimum of $20.00"
                    prefix="$"
                    precision={2}
                    controls={false}
                    defaultValue={0}
                    min={20}
                  />
                </Form.Item>
                {paymentsSlice.recurringAmount > 0 && (
                  <StyledValue>
                    Total of{" "}
                    {/*
                      If there's a remainder, and it's not included with the last payment,
                      make sure to include that.
                    */}
                    {recurringNumberOfPayments} recurring payment(s)
                  </StyledValue>
                )}
              </Space>
              <Form.Item
                label="Frequency"
                name="frequency"
                rules={[{ required: true, message: "Please select the frequency" }]}
              >
                <Select
                  placeholder="Select Frequency"
                  options={[
                    { label: "Monthly", value: "monthly" },
                    { label: "Bi-monthly", value: "bimonthly" },
                    { label: "Weekly", value: "weekly" },
                    { label: "Bi-weekly", value: "biweekly" },
                  ]}
                  onChange={onPaymentFrequencyChange}
                  popupMatchSelectWidth={false}
                />
              </Form.Item>
              <Form.Item
                label="Plan Start Date"
                name="startDate"
                rules={[{ required: true, message: "Please select the payment date" }]}
              >
                <AktDatePicker
                  onChange={changePaymentDate}
                  type="date"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </StyledCard>
            <StyledCard title="Contact">
              <Form.Item
                name="email"
                label="Email"
                tooltip="Payment receipts will be sent to this email address."
                rules={[{ required: true, message: "Please enter your email" }]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
            </StyledCard>
            <StyledCard title="Payment Information">
              <AddPaymentMethodForm prefix="newPaymentMethod" />
            </StyledCard>
          </Col>
          <Col>
            <StyledPaymentSummaryCard
              loading={isCalculateBalanceFromScheduleLoading}
              title="Payment Plan Summary"
            >
              {paymentsSlice.selectedAccounts?.map((selectedAccount, index) => {
                return (
                  <>
                    {index > 0 && <br />}
                    <Row align="middle">
                      <StyledLabel>Account Number</StyledLabel>
                      <StyledValue>{selectedAccount.externalId}</StyledValue>
                    </Row>
                    <Row align="middle">
                      <StyledLabel>Creditor Name</StyledLabel>
                      <StyledValue>{selectedAccount.currentCreditorName}</StyledValue>
                    </Row>
                    <Row align="middle">
                      <StyledLabel>
                        Original Balance{" "}
                        <Tooltip title={`Balance due as of ${moment().format(DATE_FORMAT)}`}>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </StyledLabel>
                      <StyledValue>{formatCurrency(selectedAccount.totalBalance)}</StyledValue>
                    </Row>
                    <Row align="middle">
                      <StyledLabel>Interest Rate</StyledLabel>
                      <StyledValue>{`${
                        currency(selectedAccount.interestRate, { precision: 4 }).multiply(100).value
                      }%`}</StyledValue>
                    </Row>
                    <br />
                  </>
                );
              })}
              <Divider />
              <Row align="middle">
                <StyledLabel>Recurring Amount</StyledLabel>
                <StyledValue>{formatCurrency(paymentsSlice.recurringAmount)}</StyledValue>
              </Row>
              <Row align="middle">
                <StyledLabel># of Payments</StyledLabel>
                <StyledValue>{recurringNumberOfPayments}</StyledValue>
              </Row>
              {paymentsSlice.paymentsSchedule?.[paymentsSlice.paymentsSchedule.length - 1]?.amount >
                0 && (
                <Row align="middle">
                  <StyledLabel>Final Payment</StyledLabel>
                  <StyledValue>
                    {formatCurrency(
                      paymentsSlice.paymentsSchedule[paymentsSlice.paymentsSchedule.length - 1]
                        .amount,
                    )}
                  </StyledValue>
                </Row>
              )}
              <Row align="middle">
                <StyledLabel>
                  {" "}
                  Amount due as of {paymentsSlice.startDate}{" "}
                  <Tooltip title="This amount may change based on the interest incurred from your selected payment date.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </StyledLabel>
                <StyledValue>
                  {formatCurrency(paymentsSlice.totalAmountDueAsOfStartDateWithInterest)}
                </StyledValue>
              </Row>
              <Row align="middle">
                <StyledLabel>Payment Frequency</StyledLabel>
                <StyledValue>{capitalizeFirstLetter(paymentsSlice?.paymentFrequency)}</StyledValue>
              </Row>
              <Row align="middle">
                <StyledLabel>Date of First Payment</StyledLabel>
                <StyledValue>{paymentsSlice.startDate}</StyledValue>
              </Row>
              <StyledScheduleButton type="link" onClick={() => setPlanPreviewModalVisible(true)}>
                See Full Payment Schedule
              </StyledScheduleButton>
            </StyledPaymentSummaryCard>
            <StyledDiv>
              <StyledPayButton
                block
                size="large"
                type="primary"
                htmlType="submit"
                loading={isPosting}
                disabled={isCalculateBalanceFromScheduleLoading}
              >
                Set up Payment Plan
              </StyledPayButton>
            </StyledDiv>
            <StyledButton icon={<ArrowLeftOutlined />} type="link" onClick={onPrevious}>
              Previous: Choose Payment Option
            </StyledButton>
          </Col>
        </Flex>
      </StyledForm>
      {isPlanPreviewModalVisible && (
        <Modal
          open={isPlanPreviewModalVisible}
          onCancel={() => setPlanPreviewModalVisible(false)}
          footer={null}
          width={350}
        >
          <Space direction="vertical">
            <Table
              bordered
              columns={[
                {
                  title: "Payment Date",
                  dataIndex: "date",
                  key: "date",
                },
                {
                  title: "Payment Amount",
                  dataIndex: "amount",
                  key: "amount",
                  render: (amount) => formatCurrency(amount),
                },
              ]}
              dataSource={paymentsSlice.paymentsSchedule.filter((each) => each.amount > 0)}
              pagination={{
                pageSize: 5,
                hideOnSinglePage: true,
              }}
            />
          </Space>
        </Modal>
      )}
    </>
  );
}

export default PaymentPlan;
