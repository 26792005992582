import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, message, Typography } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { resetStateAction } from "common/redux/resetStateAction";
import { useConsumerPasswordResetMutation } from "features/auth/authAPI";
import { selectIsAccessTokenStoredInRtk } from "features/auth/authSlice";
import { useWhiteLabel } from "providers/whiteLabelProvider";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
`;

const StyledDiv = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const StyledHeading = styled.h2`
  margin-top: 0;
  margin-bottom: 4px;
  text-align: center;
`;

const StyledCol = styled(Col)`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 48px;
  background: #fff;
  border-radius: 10px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100% - 90px);
  position: absolute;
  background: #f7f7f7;
  padding-bottom: 24px;
`;

function PasswordResetForm() {
  const { slug } = useWhiteLabel();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const [passwordReset, { isLoading }] = useConsumerPasswordResetMutation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const isAccessTokenStoredInRtk = useSelector(selectIsAccessTokenStoredInRtk);

  // Reset state when user navigates to password reset page
  useEffect(() => {
    if (isAccessTokenStoredInRtk) {
      dispatch(resetStateAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values) => {
    const { email, code, newPassword } = values;

    const result = await passwordReset({ email, code, password: newPassword, agencySlug: slug });
    if ("data" in result) {
      message.success("Password reset successful.", 2);
      navigate("/login");
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
      setErrorMessage("Failed to reset password. Please try again.");
    }
  };

  return (
    <StyledContainer>
      <StyledCol>
        <StyledHeading>Reset Password</StyledHeading>
        <Typography.Text type="secondary">
          Enter your email code to reset your password
        </Typography.Text>
        <StyledForm
          onFinish={onFinish}
          className="login-form"
          initialValues={{
            email: "",
            password: "",
          }}
          form={form}
        >
          <Form.Item
            name="email"
            hasFeedback
            rules={[
              { required: true, message: "Please enter email" },
              {
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" data-testid="email" />
          </Form.Item>
          <Form.Item
            name="code"
            hasFeedback
            rules={[
              { required: true, message: "Please enter code" },
              {
                type: "string",
                message: "Please enter the code sent to your email",
              },
            ]}
          >
            <Input prefix={<LockOutlined />} placeholder="Code" data-testid="code" />
          </Form.Item>
          <Form.Item
            hasFeedback
            name="newPassword"
            rules={[
              {
                required: true,
                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                message:
                  "Password must be at least 8 characters with at least 1 upper case, 1 lower case, 1 number, and 1 special character",
              },
              { required: true, message: "Please enter new password" },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Enter new password"
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            name="confirmPassword"
            rules={[
              { required: true, message: "Confirm new password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords don't match"));
                },
              }),
            ]}
            dependencies={["newPassword"]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Confirm new password"
            />
          </Form.Item>
          <Form.Item>
            <StyledButton
              type="primary"
              htmlType="submit"
              className="login-form-button"
              data-testid="login-button"
              loading={isLoading}
            >
              Change Password
            </StyledButton>
          </Form.Item>
          {errorMessage && (
            <Form.Item>
              <Alert message={errorMessage} type="error" />
            </Form.Item>
          )}
        </StyledForm>
        <StyledDiv>
          <Link to="/login">Back to Log In</Link>
        </StyledDiv>
      </StyledCol>
    </StyledContainer>
  );
}

export default PasswordResetForm;
